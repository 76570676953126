import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    const buyers = document.getElementById("buyer-sortable");
    const sellers = document.getElementById("seller-sortable");

    new Sortable(buyers, {
      animation: 150,
      onEnd: this.end.bind(this),
    });
    new Sortable(sellers, {
      animation: 150,
      onEnd: this.end.bind(this),
    });
  }

  end(event) {
    const method = "PATCH";
    const id = event.item.dataset.id;
    const data = new FormData();
    const url = event.item.dataset.sortableUrl;

    data.append("position", event.newIndex + 1);

    console.log(event);
    console.log("new", event.newIndex + 1);
    console.log("old", event.oldIndex + 1);
    console.log("url", url);

    Rails.ajax({
      url: url,
      type: method,
      data: data,
    });
  }
}
