// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
ActiveStorage.start();
window.Rails = Rails;

import "../stylesheets/application";
import "materialize-css/dist/js/materialize";
import "./cocoon-vanilla-js";

document.addEventListener("DOMContentLoaded", function () {
  var elems = document.querySelectorAll("select");
  M.FormSelect.init(elems, { classes: "select" });
  var elems = document.querySelectorAll(".datepicker");
  M.Datepicker.init(elems, { format: "yyyy-mm-dd", showClearBtn: true });
  var elems = document.querySelectorAll(".tabs");
  M.Tabs.init(elems);
  var elems = document.querySelectorAll(".tooltipped");
  M.Tooltip.init(elems);
  var elems = document.querySelectorAll(".timepicker");
  var instances = M.Timepicker.init(elems, { twelveHour: true, showClearBtn: true });
  var dropdowns = document.querySelectorAll(".dropdown-trigger");
  for (var i = 0; i < dropdowns.length; i++) {
    M.Dropdown.init(dropdowns[i], { coverTrigger: false, constrainWidth: false });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  var searchInput = document.getElementById("search-input");
  if (searchInput) {
    searchInput.addEventListener("keyup", function (event) {
      if (event.code === "Enter") {
        event.preventDefault();
        document.querySelector("form").submit();
      }
    });
  }
});

import "controllers";

window.purgePropertyPhoto = function (deleteURL) {
  const method = "DELETE";
  const url = deleteURL;

  Rails.ajax({
    url: url,
    type: method,
    success: removePhotoDiv(),
  });
};

const removePhotoDiv = () => {
  const photoDiv = document.getElementById("property-photo");
  photoDiv.style.display = "none";
};
